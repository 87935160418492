import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '@components/seo';
import ProductCard from '@components/ProductCard';
import ScrollInView from '@utility/ScrollInView';
import { getCanonicalUrl } from '@src/helpers/seoHelper';
import { viewItemListDL } from '@src/helpers/dataLayerHelper';

const Accessories = ({columns = 4, hideHeading = false}) => {
  const products = useStaticQuery(
    graphql`
      query {
        allSanityProductBasic(sort: { fields: orderRank, order: ASC }) {
          edges {
            node {
              _type
              id
              title
              orderRank
              image {
                asset {
                  _id
                }
              }
              price
              slug {
                current
              }
              downloads {
                drawings
                revit
                sketchUp
                ies
                techPack
                installInstructions
              }
              isNew
            }
          }
        }
      }
    `,
  );

  const productsBasic = products.allSanityProductBasic.edges;
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    if (!hydrated) return; // Ensure it only runs after hydration
    viewItemListDL('Accessories');
  }, [hydrated]);

  return (
    <div className="mt-12 mb-16">
      <SEO title="Accessories" >
        <link rel="canonical" href={getCanonicalUrl('/accessories')} />
      </SEO>
      <ScrollInView animateType="fadeInUp">
        <div className="wrapper">
          <div className="row">
            <div className="col-12">
              {!hideHeading && <h1 className="type-sans-630 md:type-sans-930 mb-12 md:mb-24">
                Accessories
              </h1>}
            </div>
          </div>
          <div className="row">
            {productsBasic.map((productBasic) => (
              <div key={productBasic.node.id} className={`col-6 ${columns === 4 ? 'lg:col-3' : 'lg:col-4'}`}>
                <ProductCard data={{ ...productBasic.node }} />
              </div>
            ))}
          </div>
        </div>
      </ScrollInView>
    </div>
  );
};

export default Accessories;
